import { useCallback } from "react";
import { Avatar } from "primereact/avatar";
import { InputSwitch } from "primereact/inputswitch";

import * as HelpUrls from "../../Common/Constants/helpUrls";
import { useGreeting } from "../../hooks/useGreeting";
import FeaturedContent from "./FeaturedContent";
import useAppNavigate from "../../hooks/useAppNavigate";
import { AppRoute } from "../../AppRoute";
import Page from "../../components/Page";
import TopBar from "../../components/TopBar";
import HeaderTile from "./components/HeaderTile";
import useExpertMode from "../../hooks/useExpertMode";

function Dashboard() {
  const navigate = useAppNavigate();
  const { greeting } = useGreeting();
  // const userProfile = useSelector(selectors.selectUserProfile);
  //const config = useSelector(selectors.selectUiConfig); 

  const company = "BiBro";
  const userProfileName = ""; //userProfile?.name;

  function openInNewTab(url: string) {
    window.open(url, "_blank", "noopener,noreferrer");
  }

  const onHelpTileClick = useCallback(() => {
    openInNewTab(HelpUrls.docs);
  }, []);
  const onPulseTileClick = useCallback(() => {
    navigate(AppRoute.Pulse);
  }, [navigate]);
  const onNewPulseTileClick = useCallback(() => {
    navigate(AppRoute.NewPulse);
  }, [navigate]);
  const onUsersTileClick = useCallback(() => {
    navigate(AppRoute.Home);
  }, [navigate]);

  const { isExpertModeSet, toggleExpertMode } = useExpertMode();
  const onExpertMode = useCallback(toggleExpertMode, [toggleExpertMode]);

  const topBar = (
    <TopBar size="large">
      <div className="title text-white">
        <i className="fa fa-hand-spock-o pr-2" />
        {greeting}, <b>{userProfileName}</b>
      </div>
      <div className="info">Select the use case you need to work on from the available actions below.</div>
      <div className="tiles-header-content-wrapper">
        <div className="tiles-header-stack">
          <HeaderTile
            onClick={onNewPulseTileClick}
            icon="fa-bar-chart"
            title="1. Create Pulse"
            text="I want to create a new pulse and analyze data."
          />
          <HeaderTile
            onClick={onPulseTileClick}
            icon="fa-bar-chart"
            title="2. Manage pulses"
            text="I want to manage existing pulses, change pulses scheduling."
          />
          <HeaderTile
            onClick={onUsersTileClick}
            icon="fa-users"
            title="3. Invite Users"
            text="I want to add a new user to BiBro so they can access it."
          />
          <HeaderTile
            onClick={onHelpTileClick}
            icon="fa-question-circle"
            title="Help & Support"
            text="You can see our user guide, send us feedback, report a bug, etc."
          />
        </div>
      </div>
    </TopBar>
  );

  return (
    <Page windowTitle="Dashboard" className="page-dashboard" topBar={topBar} >
      <div className="content-wrapper">
        <TenantBanner company={company} />
        <FeaturedContent isExpertModeSet={isExpertModeSet} />
        <div className="flex align-items-center justify-content-center gap-3 mt-5" onClick={onExpertMode}>
          <span>
            {isExpertModeSet
              ? "Turn the expert mode off."
              : "Turn the expert mode on (advanced settings)."}
          </span>
          <InputSwitch checked={isExpertModeSet} />
        </div>
      </div>
    </Page>
  );
}

function TenantBanner({ company }: { company: string; }) {
  const initials = `${company.charAt(0).toUpperCase()}${company.charAt(1).toUpperCase()}`;
  return (
    <div className="tenant">
      <div>
        <Avatar shape="circle" size="large" label={initials} />
      </div>
      <div>
        <div className="company">{company}</div>
        <div className="domain">Your digital data analyst</div>
      </div>
    </div>
  );
}

export default Dashboard;
