import { InputText } from "primereact/inputtext";
import SidePanel from "../../components/SidePanel";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { FormikErrors, useFormik } from "formik";
import { cannotBeEmpty } from "../../Common/Constants/validationMessages";
import useToast from "../../hooks/useToast";
import { Pulse, useChannelsQuery, useLazyPulseQuery, useUpdatePulseMutation } from "../../api/generated";
import { useCallback, useEffect, useState } from "react";
import { RequestError } from "../../api/graphqlBaseQueryTypes";
import { act } from "react-dom/test-utils";
import { InputTextarea } from "primereact/inputtextarea";
import Cron from "react-js-cron";
import { Dropdown } from "primereact/dropdown";

type PulseEditorProps = {
  visible: boolean;
  pulseId: string | null;
  onClose: (refresh: boolean) => void;
};

function PulseEditor(props: PulseEditorProps) {
  const toast = useToast();
  const [editedPulse, setEditedPulse] = useState<Pulse | undefined>(undefined);
  const [crontab, setCrontab] = useState<string>("");
  const [getPulse] = useLazyPulseQuery();
  const [savePulse] = useUpdatePulseMutation();
  const {data} = useChannelsQuery();

  useEffect(() => {
    const actionGetPulse = async (id: string) => {
      try {
        const pulse = await getPulse({ id: id }).unwrap();
        let loadedPulse: Pulse = {
          id: pulse?.pulse?.id || "",
          name: pulse?.pulse?.name || "",
          topic: pulse?.pulse?.topic || "",
          description: pulse?.pulse?.description || "",
          crontab: pulse?.pulse?.crontab || "",
        };
        setEditedPulse(loadedPulse);
      } catch (ex) {
        let e = ex as RequestError;
        let emsg = "An unknown error occurred while loading the pulse";
        if (e.errors.length > 0) {
          emsg = e.errors[0].message;
        }
        toast.show({
          severity: "error",
          detail: emsg,
          life: 4000,
        });
      }
    };

    if (props.pulseId !== null) {
      actionGetPulse(props.pulseId);
    } else {
      setEditedPulse(undefined);
    }
  }, [props.visible, toast, props.pulseId, getPulse]);

  const pulseForm = useFormik({
    initialValues: {
      name: "",
      topic: "",
      description: "",
    },
    validate: data => {
      let errors: FormikErrors<{ name: string; topic: string }> = {};

      if (!data.topic) {
        errors.topic = cannotBeEmpty;
      } else if (!/^[a-z-]{2,128}$/.test(data.topic)) {
        errors.topic = "Invalid input text. The allowed characters are: a-z -";
      }

      if (!data.name) {
        errors.name = cannotBeEmpty;
      }

      return errors;
    },
    onSubmit: async data => {
      try {
        await savePulse({
          updatePulse: {
            id: editedPulse!.id || "",
            name: data.name,
            topic: data.topic,
            description: data.description,
            crontab: crontab,
          },
        }).unwrap();
        props.onClose(true);
        toast.show({
          severity: "success",
          detail: `Pulse '${data.name}' has been saved successfully`,
          life: 4000,
        });
      } catch (ex) {
        let e = ex as RequestError;
        let emsg = "There was a problem saving the pulse";
        if (e.errors.length > 0) {
          emsg = e.errors[0].message;
        }
        toast.show({
          severity: "error",
          detail: emsg,
          life: 4000,
        });
      }
    },
  });

  useEffect(
    () => {
      if (editedPulse) {
        pulseForm.setValues({
          name: editedPulse.name,
          topic: editedPulse.topic,
          description: editedPulse.description || "",
        });
        setCrontab(editedPulse.crontab || "");
      } else {
        pulseForm.resetForm();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editedPulse]
  );

  const onCancel = () => {
    props.onClose(false);
  };

  const onSave = useCallback(() => pulseForm.submitForm(), [pulseForm]);

  const isFieldInvalid = (name: "name" | "topic") => !!(pulseForm.touched[name] && pulseForm.errors[name]);
  const getFormErrorMessage = (name: "name" | "topic") => {
    return isFieldInvalid(name) && <small className="p-error">{pulseForm.errors[name]}</small>;
  };

  return (
    <>
      <SidePanel title="Edit Pulse" onClose={onCancel} visible={props.visible}>
        {!editedPulse && <div className="side-panel-loading">Loading...</div>}
        {editedPulse && (
          <>
            <div className="side-panel-content side-panel-content-section">
              <form onSubmit={pulseForm.handleSubmit}>
                <div className="field">
                  <label htmlFor="pulse-topic">Topic</label>
                  <Dropdown
                    id="pulse-topic"
                    name="topic"
                    value={pulseForm.values.topic}
                    options={data?.channels.map(c => c.name) || []}
                    className={classNames("w-full", { "p-invalid": isFieldInvalid("topic") })}
                    onChange={pulseForm.handleChange}
                  />
                  {getFormErrorMessage("topic")}
                </div>
                <div className="field">
                  <label htmlFor="pulse-name">Name</label>
                  <InputText
                    id="pulse-name"
                    name="name"
                    value={pulseForm.values.name}
                    className={classNames("w-full", { "p-invalid": isFieldInvalid("name") })}
                    onChange={pulseForm.handleChange}
                  />
                  {getFormErrorMessage("name")}
                </div>
                <div className="field">
                  <label htmlFor="pulse-crontab">
                    Crontab <strong>({crontab})</strong>
                  </label>
                  <Cron value={crontab} setValue={setCrontab} />
                </div>
                <div className="field">
                  <label htmlFor="pulse-name">Business description</label>
                  <InputTextarea
                    id="pulse-description"
                    name="description"
                    value={pulseForm.values.description}
                    className={classNames("w-full")}
                    onChange={pulseForm.handleChange}
                    rows={8}
                    autoResize
                  />
                </div>
              </form>
            </div>
            <div className="side-panel-controls">
              <Button label="Save" className="p-button-sm" icon="fa-regular fa-save" onClick={onSave} />
              <Button label="Cancel" className="p-button-sm p-button-text p-button-secondary" onClick={onCancel} />
            </div>
          </>
        )}
      </SidePanel>
    </>
  );
}

export default PulseEditor;
