import { PulseRequestData } from "./types";

export const deserializePulseRequestData = (jsonString: string): PulseRequestData => {
    const jsonObject = JSON.parse(jsonString);
    return new PulseRequestData(jsonObject);
}

export const shuffleArray = <T extends unknown>(arrIn: T[]) => {
    const array = [...arrIn];
    let currentIndex = array.length;

    while (currentIndex !== 0) {
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}
