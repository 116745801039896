import { Button } from "primereact/button";
import { QUIZ_QUESTION_COUNT } from "../../types";

type ResultContentProps = {
    score: number;
    restartQuiz: () => void;
}

export const ResultContent = ({ score, restartQuiz }: ResultContentProps) => 
    <>
        <div className="title text-white text-left result-title">
            Zodpověděli jste <b>{score}/{QUIZ_QUESTION_COUNT}</b> otázek správně
        </div>
        <div className="info  text-left">Děkujeme za Vaši účast</div>
        <Button label="Restartovat kvíz &nbsp;" className="quiz-restart-btn" icon="fa fa-refresh" iconPos="right" onClick={restartQuiz} />
    </>
