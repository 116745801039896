export enum QuizCategoryType {
    LEGO = 1,
    Finance = 2,
    Movies = 3,
    Olympics = 4,
}

export const quizCategories = [
    {
        id: QuizCategoryType.LEGO,
        icon: "fa-cube",
        title: "LEGO®",
        description: "Otestujte své znalosti ze světa LEGO® stavebnic.",
    },
    {
        id: QuizCategoryType.Finance,
        icon: "fa-line-chart",
        title: "Forbes a finanční trhy",
        description: "Máte přehled o tom, co se děje ve světě financí?",
    },
    {
        id: QuizCategoryType.Movies,
        icon: "fa-video-camera",
        title: "Filmy a seriály",
        description: "Zabojujte o titul znalce filmového světa.",
    },
    {
        id: QuizCategoryType.Olympics,
        icon: "fa-trophy",
        title: "Olympijské hry",
        description: "Získáte zlatou medaili za znalosti o olympijských hrách?",
    },
];