import { useCallback, useMemo, useState } from "react";
import Page from "../../components/Page";
import { PulseView, QuizMainScreen, QuizTopBar } from "./components";
import { allQuestions, QuizCategoryType } from "./questionsData";
import { QuestionDefinition, QUIZ_QUESTION_COUNT, QuizState } from "./types";
import { shuffleArray } from "./utils";

const QuizPage = () => {
    const [quizState, setQuizStateState] = useState<QuizState>("selectScreen");
    const [questions, setQuestions] = useState<QuestionDefinition[]>([]);
    const [category, setCategory] = useState<QuizCategoryType | null>();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
    const [pulseDate, setPulseDate] = useState<Date>(new Date());
    const [isLoading, setIsLoading] = useState(true);

    const currentQuestion = useMemo(() => questions[currentQuestionIndex], [currentQuestionIndex, questions]);

    const showBiBroPulse = useCallback(() => setQuizStateState("pusleScreen"), []);
    const pulseViewGoBack = useCallback(() => setQuizStateState("quizScreen"), []);

    const resetAnswers = useCallback(() => {
        setCurrentQuestionIndex(0);
        setScore(0);
        setSelectedAnswer(null);
    }, []);

    const restartQuiz = useCallback(() => {
        resetAnswers();
        setQuizStateState("selectScreen");
    }, [resetAnswers]);

    const goToNextAnswer = useCallback(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedAnswer(null);
    }, [currentQuestionIndex]);

    const goToSummary = useCallback(() => {
        setQuizStateState("resultScreen");
    }, []);

    const onTopicSelect = useCallback((category: QuizCategoryType) => {
        resetAnswers();

        setQuestions(shuffleArray(allQuestions[category]).slice(0, QUIZ_QUESTION_COUNT));
        setCategory(category);
        setQuizStateState("quizScreen");
    }, [resetAnswers]);

    const markAnswer = useCallback((answerIndex: number) => {
        if(selectedAnswer === null) {
            setSelectedAnswer(answerIndex);
            setPulseDate(new Date());
            setIsLoading(true);
            if(currentQuestion !== undefined) {
                if(answerIndex === currentQuestion.correctAnswerIndex) {
                    setScore(score + 1);
                }
            }

            setTimeout(() => {
                setIsLoading(false);
            }, Math.random() * 600 + 250);
        }
    }, [currentQuestion, score, selectedAnswer]);

    return (
        <Page
            windowTitle={'BiBro Quiz - FORBES Next Big Thing 2024'}
            className="page-dashboard page-detail-pulse"
            topBar={
                <QuizTopBar
                    restartQuiz={restartQuiz}
                    score={score}
                    pulseViewGoBack={pulseViewGoBack}
                    quizState={quizState}
                    onTopicSelect={onTopicSelect}
                    question={currentQuestion}
                    questionIndex={currentQuestionIndex}
                    topic={category ?? undefined}
                />
            }
        >
            {quizState === "quizScreen" && (
                <QuizMainScreen
                    isLoading={isLoading}
                    markAnswer={markAnswer}
                    selectedAnswer={selectedAnswer}
                    topic={category!}
                    questionIndex={currentQuestionIndex}
                    question={currentQuestion}
                    showBiBroPulse={showBiBroPulse}
                    goToNextAnswer={goToNextAnswer}
                    goToSummary={goToSummary}
                />
            )}
            {quizState === "pusleScreen" && (
                <PulseView dateTime={pulseDate} pulse={currentQuestion.biBroResponse!.data} />
            )}
        </Page>
    );
}

export default QuizPage;