import { InputText } from "primereact/inputtext";
import { AppRoute, IAppRoute } from "../../AppRoute";
import HeaderPanel from "../../components/HeaderPanel";
import Page from "../../components/Page";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { getWebSocketUrl, useWebSocket } from "../../api/websocket/ws";
import MarkdownPreview from "@uiw/react-markdown-preview";
import {
  WebSocketMessage,
  WebSocketMessageIntentRequest,
  WebSocketMessageIntentRequestQuestion,
  WebSocketMessageIntentResponseDataItem,
  WebSocketMessagePublishRequest,
  WebSocketMessagePublishResponseData,
  WebSocketMessagePulseRequest,
  WebSocketMessagePulseResponseData,
  WebSocketMessageTopicRequest,
  WebSocketMessageTopicResponseDataItem,
  WebSocketMessageTopicResponseQuestion,
  WebSocketResponseData,
} from "../../api/websocket/dataTypes";
import Cron from "react-js-cron";
import "react-js-cron/dist/styles.css";
import { useUpdatePulseMutation, PulseUpdateInput, useChannelsQuery } from "../../api/generated";
import useToast from "../../hooks/useToast";
import { RequestError } from "../../api/graphqlBaseQueryTypes";
import { Dropdown } from "primereact/dropdown";

enum AgentState {
  IDLE,
  RUNNING,
  ERROR,
  DONE,
}

class AgentMessages {
  name: string;
  state: AgentState = AgentState.IDLE;
  messages: string[] = [];

  constructor(name: string) {
    this.name = name;
  }
}

class AgentStruct {
  topic: AgentMessages = new AgentMessages("Topic");
  intent: AgentMessages = new AgentMessages("Intent");
  pulse: AgentMessages = new AgentMessages("Pulse");
  publish: AgentMessages = new AgentMessages("Publish");
}

class ServerStatus {
  connected: boolean = false;
  message: string = "Not connected";
}

function NewPulse(): JSX.Element {
  // enum with form states
  enum PulseState {
    NEW = 0,
    TOPIC,
    TOPIC_READY,
    INTENT,
    INTENT_READY,
    PULSE,
    PULSE_READY,
    PUBLISH,
    PUBLISH_READY,
  }

  const guid = () => {
    const s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    return s4() + s4() + s4() + s4() + s4() + s4() + s4();
  };
  const [pulseState, setPulseState] = React.useState(PulseState.NEW);
  const [agentMsg, setAgentMsg] = React.useState<AgentStruct>(new AgentStruct());
  const [clientId, setClientId] = useState<string>("");
  const [serverStatus, setServerStatus] = useState<ServerStatus>(new ServerStatus());
  const [topicRequest, setTopicRequest] = useState<WebSocketMessageTopicRequest | null>(null);
  const [topicResponse, setTopicResponse] = useState<WebSocketMessageTopicResponseDataItem | null>(null);
  const [intentQuestion, setIntentQuestion] = useState<WebSocketMessageTopicResponseQuestion | null>(null);
  const [intentResponse, setIntentResponse] = useState<WebSocketMessageIntentResponseDataItem | null>(null);
  const [pulseRequest, setPulseRequest] = useState<WebSocketMessagePulseRequest | null>(null);
  const [pulseResponse, setPulseResponse] = useState<WebSocketMessagePulseResponseData | null>(null);
  const [publishRequest, setPublishRequest] = useState<WebSocketMessagePublishRequest | null>(null);
  const [publishResponse, setPublishResponse] = useState<WebSocketMessagePublishResponseData | null>(null);
  const [newTopicDescription, setNewTopicDescription] = useState<string>("");
  const [scheduledPulseName, setScheduledPulseName] = useState<string>("");
  const [schedulePublishCron, setSchedulePublishCron] = useState<string>("0 5 * * 1"); // default to every Monday at 5:00
  const [scheduledPulseId, setScheduledPulseId] = useState<string>("");
  const [newPulseTopic, setNewPulseTopic] = useState<string>("");
  const [newPulseTopicError, setNewPulseTopicError] = useState<string>("");
  const [newPulseTopicDescription, setNewPulseTopicDescription] = useState<string>("");
  const statusClass = serverStatus.connected ? "fa fa-check-circle green" : "fa fa-exclamation-circle red";
  const [updatePulse] = useUpdatePulseMutation();
  const toast = useToast();
  const { data: dataChannel } = useChannelsQuery();

  useEffect(() => {
    // connect websocket
    const c = guid();
    setClientId(c);
  }, []);

  // automatic start of pulse
  useEffect(
    () => {
      if (pulseState === PulseState.INTENT_READY && intentResponse != null && topicRequest != null) {
        const msgPulse = new WebSocketMessagePulseRequest(
          clientId,
          intentResponse.task,
          intentResponse.form,
          intentResponse.dimensions,
          intentResponse.metric,
          intentResponse.question,
          intentResponse.reason,
          intentResponse.data_source,
          topicRequest.topic,
          intentResponse.topic_description,
          intentResponse.visualization_description,
          intentResponse.data_format
        );
        setPulseRequest(msgPulse);
        const msgToSend = new WebSocketMessage("pulse_in", msgPulse);
        sendMessage(msgToSend);
        setPulseState(PulseState.PULSE);
        setAgentMsg(prevAgentMsg => ({
          ...prevAgentMsg,
          pulse: {
            ...prevAgentMsg.pulse,
            state: AgentState.RUNNING,
          },
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intentResponse, pulseState, topicRequest]
  );

  const handleEvent = (channel: string, message: string) => {
    // call setAgentMsg based on channel
    switch (channel) {
      case "topic_out":
        setAgentMsg(prevAgentMsg => ({
          ...prevAgentMsg,
          topic: {
            ...prevAgentMsg.topic,
            messages: [...prevAgentMsg.topic.messages, message],
          },
        }));
        break;
      case "intent_out":
        setAgentMsg(prevAgentMsg => ({
          ...prevAgentMsg,
          intent: {
            ...prevAgentMsg.intent,
            messages: [...prevAgentMsg.intent.messages, message],
          },
        }));
        break;
      case "pulse_out":
        setAgentMsg(prevAgentMsg => ({
          ...prevAgentMsg,
          pulse: {
            ...prevAgentMsg.pulse,
            messages: [...prevAgentMsg.pulse.messages, message],
          },
        }));
        break;
      case "publish_out":
        setAgentMsg(prevAgentMsg => ({
          ...prevAgentMsg,
          publish: {
            ...prevAgentMsg.publish,
            messages: [...prevAgentMsg.publish.messages, message],
          },
        }));
        break;
      default:
        console.log("Unknown channel ", channel);
    }
  };

  const handleTopicResponse = (data: WebSocketResponseData) => {
    console.log("Topic response ", data);
    if (data.type === "error") {
      setPulseState(PulseState.NEW);
      setAgentMsg(prevAgentMsg => ({
        ...prevAgentMsg,
        topic: {
          ...prevAgentMsg.topic,
          state: AgentState.ERROR,
          messages: [...prevAgentMsg.topic.messages, `Error: ${data.data}`],
        },
      }));
    }
    if (data.type === "response") {
      setAgentMsg(prevAgentMsg => ({
        ...prevAgentMsg,
        topic: {
          ...prevAgentMsg.topic,
          state: AgentState.DONE,
        },
      }));
      const dataArr: WebSocketMessageTopicResponseDataItem[] = data.data;
      setTopicResponse(dataArr[0]);
      setPulseState(PulseState.TOPIC_READY);
    }
  };

  const handleIntentResponse = (data: WebSocketResponseData) => {
    console.log("Intent response ", data);
    if (data.type === "error") {
      setPulseState(PulseState.NEW);
      setAgentMsg(prevAgentMsg => ({
        ...prevAgentMsg,
        intent: {
          ...prevAgentMsg.intent,
          state: AgentState.ERROR,
          messages: [...prevAgentMsg.intent.messages, `Error: ${data.data}`],
        },
      }));
    }
    if (data.type === "response") {
      setAgentMsg(prevAgentMsg => ({
        ...prevAgentMsg,
        intent: {
          ...prevAgentMsg.intent,
          state: AgentState.DONE,
        },
      }));
      const dataArr: WebSocketMessageIntentResponseDataItem[] = data.data;
      setIntentResponse(dataArr[0]);
      setPulseState(PulseState.INTENT_READY);
    }
  };

  const handlePulseResponse = (data: WebSocketResponseData) => {
    console.log("Pulse response ", data);
    if (data.type === "error") {
      setPulseState(PulseState.NEW);
      setAgentMsg(prevAgentMsg => ({
        ...prevAgentMsg,
        pulse: {
          ...prevAgentMsg.pulse,
          state: AgentState.ERROR,
          messages: [...prevAgentMsg.pulse.messages, `Error: ${data.data}`],
        },
      }));
    }
    if (data.type === "response") {
      setAgentMsg(prevAgentMsg => ({
        ...prevAgentMsg,
        pulse: {
          ...prevAgentMsg.pulse,
          state: AgentState.DONE,
        },
      }));
      const dataResp: WebSocketMessagePulseResponseData = data.data;
      setPulseResponse(dataResp);
      setPulseState(PulseState.PULSE_READY);
      setSchedulePublishCron("0 5 * * 1"); // default every Monday on Monday
    }
  };

  const handlePublishResponse = (data: WebSocketResponseData) => {
    console.log("Publish response ", data);
    if (data.type === "error") {
      setPulseState(PulseState.PULSE_READY);
      setAgentMsg(prevAgentMsg => ({
        ...prevAgentMsg,
        publish: {
          ...prevAgentMsg.publish,
          state: AgentState.ERROR,
          messages: [...prevAgentMsg.publish.messages, `Error: ${data.data}`],
        },
      }));
    }
    if (data.type === "response") {
      setAgentMsg(prevAgentMsg => ({
        ...prevAgentMsg,
        publish: {
          ...prevAgentMsg.publish,
          state: AgentState.DONE,
        },
      }));
      const dataResp: WebSocketMessagePublishResponseData = data.data;
      setPublishResponse(dataResp);
      setPulseState(PulseState.PUBLISH_READY);
    }
  };

  const [wsIsReady, wsErrorMessage, wsMessage, wsSend] = useWebSocket({ correlation_id: clientId });

  useEffect(() => {
    const msg = wsIsReady ? "Connected" : `Not connected (${wsErrorMessage})`;
    setServerStatus({ connected: wsIsReady, message: msg });
  }, [wsIsReady, wsErrorMessage]);

  useEffect(
    () => {
      if (wsMessage) {
        console.log("Message from server >>> ", wsMessage);
        // get data from message - must be WebSocketResponseData
        const responseData: WebSocketResponseData = wsMessage.data;
        // if it is event call handler
        if (responseData.type === "event") {
          handleEvent(wsMessage.channel, responseData.data.message);
        } else {
          switch (wsMessage.channel) {
            case "topic_out":
              handleTopicResponse(responseData);
              break;
            case "intent_out":
              handleIntentResponse(responseData);
              break;
            case "pulse_out":
              handlePulseResponse(responseData);
              break;
            case "publish_out":
              handlePublishResponse(responseData);
              break;
            default:
              console.log("Unknown channel ", wsMessage.channel);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wsMessage]
  );

  const sendMessage = (data: WebSocketMessage) => {
    if (wsSend) {
      wsSend(data);
    }
  };

  const submitQuery = () => {
    // validate
    if (!validateTopicIsValid(newPulseTopic)) {
      return;
    }
    // collect topic and topic_description
    const msgTopic = new WebSocketMessageTopicRequest(clientId, newPulseTopic, newPulseTopicDescription, 5);
    setTopicRequest(msgTopic);
    setNewTopicDescription(newPulseTopicDescription);
    setScheduledPulseName(newPulseTopic + ": " + newPulseTopicDescription);
    const msgToSend = new WebSocketMessage("topic_in", msgTopic);
    sendMessage(msgToSend);
    setAgentMsg(new AgentStruct());
    setAgentMsg(prevAgentMsg => ({
      ...prevAgentMsg,
      topic: {
        ...prevAgentMsg.topic,
        state: AgentState.RUNNING,
      },
    }));
    setPulseState(PulseState.TOPIC);
  };

  const reanalyzePulse = () => {
    setPulseState(PulseState.NEW);
    setAgentMsg(new AgentStruct());
  };

  const recreatePulse = () => {
    const msgTopic = new WebSocketMessageTopicRequest(clientId, topicRequest?.topic || "", newTopicDescription, 5);
    setTopicRequest(msgTopic);
    setScheduledPulseName((topicRequest?.topic || "") + ": " + newTopicDescription);
    setAgentMsg(prevAgentMsg => ({
      ...prevAgentMsg,
      intent: {
        ...prevAgentMsg.intent,
        state: AgentState.RUNNING,
        messages: [],
      },
      pulse: {
        ...prevAgentMsg.pulse,
        state: AgentState.IDLE,
        messages: [],
      },
      publish: {
        ...prevAgentMsg.publish,
        state: AgentState.IDLE,
        messages: [],
      },
    }));
    const question = new WebSocketMessageIntentRequestQuestion(
      intentQuestion?.question || "",
      intentQuestion?.metric || "",
      intentQuestion?.reason || "",
      intentQuestion?.data_source || "",
      newTopicDescription
    );
    setIntentQuestion(question);
    setPulseState(PulseState.INTENT);
    const msgIntent = new WebSocketMessageIntentRequest(clientId, [question]);
    const msgToSend = new WebSocketMessage("intent_in", msgIntent);
    sendMessage(msgToSend);
  };

  const schedulePulse = async () => {
    try {
      if (pulseResponse === null) {
        return;
      }
      const pulseData: PulseUpdateInput = {
        id: pulseResponse.pulse_id,
        name: scheduledPulseName,
        topic: topicRequest?.topic || "",
        crontab: schedulePublishCron,
        description: pulseResponse.description,
      };
      await updatePulse({ updatePulse: pulseData }).unwrap();
      toast.show({
        severity: "success",
        detail: `The pulse '${pulseData.name}' has been scheduled successfully.`,
        life: 4000,
      });
      setScheduledPulseId(pulseResponse.pulse_id);
      publishPulse();
    } catch (ex) {
      let e = ex as RequestError;
      let emsg = "An unknown error occurred while scheduling pulse";
      if (e.errors.length > 0) {
        emsg = e.errors[0].message;
      }
      toast.show({
        severity: "error",
        detail: emsg,
        life: 4000,
      });
    }
  };

  const publishPulse = () => {
    let pr = pulseRequest;
    if (!pr) {
      setPulseState(PulseState.NEW);
      return;
    }
    if (!pulseResponse) {
      setPulseState(PulseState.NEW);
      return;
    }
    pr.correlation_id = clientId;
    const msgPublish = new WebSocketMessagePublishRequest(pr, pulseResponse);
    setPublishRequest(msgPublish);
    const msgToSend = new WebSocketMessage("publish_in", msgPublish);
    sendMessage(msgToSend);
    setAgentMsg(prevAgentMsg => ({
      ...prevAgentMsg,
      publish: {
        ...prevAgentMsg.publish,
        state: AgentState.RUNNING,
        messages: [],
      },
    }));
    setPulseState(PulseState.PUBLISH);
  };

  const proceedQuestion = (question: WebSocketMessageTopicResponseQuestion) => {
    setIntentQuestion(question);
    setPulseState(PulseState.INTENT);
    const msgIntent = new WebSocketMessageIntentRequest(clientId, [question]);
    const msgToSend = new WebSocketMessage("intent_in", msgIntent);
    sendMessage(msgToSend);
    setAgentMsg(prevAgentMsg => ({
      ...prevAgentMsg,
      intent: {
        ...prevAgentMsg.intent,
        state: AgentState.RUNNING,
      },
    }));
  };

  const handleNewPulseTopicChange = (value: string) => {
    setNewPulseTopic(value);
    validateTopicIsValid(value);
  };

  const validateTopicIsValid = (value: string) => {
    let msg = "";
    if (value === "") {
      msg = "Topic cannot be empty";
    } else if (value.length > 128) {
      msg = "Topic is too long, maximum length is 128 characters";
    } else if (value.length < 2) {
      msg = "Topic is too short, minimum length is 2 characters";
    } else if (!/^[a-z-]{2,128}$/.test(value)) {
      msg = "Invalid input text. The allowed characters are: a-z -";
    } else {
      msg = "";
    }
    setNewPulseTopicError(msg);
    return msg === "";
  };

  function ErrorMessage(props: { message: string }) {
    if (props.message === "") {
      return <></>;
    }
    return <small className="p-error">{props.message}</small>;
  }

  function TemporaryDataTable(data: any) {
    if (!data || !data.data || !data.data.data || !Array.isArray(data.data.data) || data.data.data.length === 0) {
      return <div>Empty dataset</div>;
    }
    const dataArr = data.data.data;
    // expecting that data is array of objects
    // get column names from first row
    const firstRow = dataArr[0];
    return (
      <div>
        <table className="preview-table">
          <thead>
            <tr>
              {Object.keys(firstRow).map((key, index) => (
                <th key={index}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataArr.map((row: any, index: number) => (
              <tr key={index}>
                {Object.keys(row).map((key, index) => (
                  <td key={index}>{row[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  function AgentMessageTree(props: { agent: AgentMessages }) {
    const fa_icon_name = (state: AgentState) => {
      switch (state) {
        case AgentState.IDLE:
          return "fa fa-pause-circle-o gray";
        case AgentState.RUNNING:
          return "fa fa-spinner fa-spin blue";
        case AgentState.ERROR:
          return "fa fa-exclamation-circle red";
        case AgentState.DONE:
          return "fa fa-check-circle green";
      }
    };
    return (
      <div>
        <div className="agent-label">
          <i className={fa_icon_name(props.agent.state)} /> {props.agent.name}
        </div>
        <ul className="agent-ul">
          {props.agent.messages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </div>
    );
  }

  function StatusMessage(props: { msg: string | null | undefined }) {
    // "[teams](https://teams.microsoft.com/l/message/19:ba80e44a277d4da8acaa9a83fa896014@thread.tacv2/1713993297218)"
    // name is "teams"
    // link is "https://teams.microsoft.com/l/message/19:ba80e44a277d4da8acaa9a83fa896014@thread.tacv2/1713993297218"
    // link should be encoded
    const parseName = (link: string) => {
      // parse name from [] brackets
      try {
        const name = link.split("[")[1].split("]")[0];
        return name;
      } catch (e) {
        return "";
      }
    };
    const parseUrl = (link: string) => {
      // parse url from () brackets
      try {
        const url = link.split("(")[1].split(")")[0];
        // url encode
        return url;
      } catch (e) {
        return "";
      }
    };
    if (!props.msg) {
      return <>N/A</>;
    }
    if (props.msg.startsWith("story_id:")) {
      const storyId = props.msg.split("story_id:")[1].trim();
      const links = storyId.split(";");
      return (
        <>
          {links.map((link, index) => (
            <>
              <a href={`${parseUrl(link)}`} target="_blank" rel="noreferrer">
                <i className="fa fa-external-link" style={{ fontSize: "10px" }}></i> {parseName(link)}
              </a>{" "}
              &nbsp;{" "}
            </>
          ))}
        </>
      );
    }
    return <>{props.msg}</>;
  }

  return (
    <Page windowTitle={newPulseRoute.title} className="page-new-pulse">
      <HeaderPanel titleText={newPulseRoute.title} />
      <div className="content-wrapper">
        <div className="grid mb-3">
          <div className="col-8">
            <div className="main-tile" style={{ height: "100%" }}>
              <i className="fa fa-info-circle" />
              &nbsp; Define topic (which is used as channel name in user facing application) and if needed you can also
              specify more details in field specific description. Ask your data by natural language!
            </div>
          </div>
          <div className="col-4">
            <div className="main-tile" style={{ height: "100%" }}>
              AI agents backend:
              <div className="agent-label">
                <i className={statusClass} />
                &nbsp; {serverStatus.message}
              </div>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col-8">
            {pulseState === PulseState.NEW && (
              <>
                <form>
                  <div className="grid">
                    <div className="col-3 flex align-items-center flex-wrap">
                      <label htmlFor="topic" style={{ textAlign: "left", fontSize: 16 }}>
                        Topic:
                      </label>
                    </div>
                    <div className="col-9">
                      <Dropdown
                        id="topic"
                        name="topic"
                        options={dataChannel?.channels.map(c => c.name) || []}
                        className={classNames("w-full")}
                        disabled={pulseState !== PulseState.NEW}
                        value={newPulseTopic}
                        onChange={e => handleNewPulseTopicChange(e.value)}
                      />
                      <ErrorMessage message={newPulseTopicError} />
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-3 flex align-items-center flex-wrap">
                      <label htmlFor="topic_description" style={{ textAlign: "left", fontSize: 16 }}>
                        Specific description:
                      </label>
                    </div>
                    <div className="col-9">
                      <InputText
                        id="topic_description"
                        name="topic_description"
                        className={classNames("w-full")}
                        disabled={pulseState !== PulseState.NEW}
                        value={newPulseTopicDescription}
                        onChange={e => setNewPulseTopicDescription(e.currentTarget.value)}
                      />
                    </div>
                  </div>
                </form>
                <div className="grid">
                  <div className="col-3"></div>
                  <div className="col-9 flex align-items-center flex-wrap">
                    <Button label="Ask pulse" onClick={submitQuery} />
                  </div>
                </div>
              </>
            )}
            {pulseState >= PulseState.TOPIC && (
              <div>
                {pulseState === PulseState.TOPIC && (
                  <h3 className="agent-label" style={{ textAlign: "left", fontSize: 16 }}>
                    <i className="fa fa-spinner fa-spin blue" />
                    &nbsp; Processing topic
                  </h3>
                )}
                {pulseState >= PulseState.TOPIC_READY && (
                  <h3 className="agent-label" style={{ textAlign: "left", fontSize: 16 }}>
                    <i className="fa fa-check-circle green" />
                    &nbsp; Processing topic
                  </h3>
                )}
                <div className="question-tile">
                  <div>
                    <div>
                      <strong>Topic:</strong> {topicRequest?.topic}
                    </div>
                    <div>
                      <strong>Specific description:</strong> {topicRequest?.description}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {pulseState === PulseState.TOPIC_READY && topicResponse != null && (
              <div>
                <h3 style={{ textAlign: "left", fontSize: 16 }}>
                  <i className="fa fa-info-circle" />
                  &nbsp; Proposed questions
                </h3>
                {topicResponse.questions.map((question, index) => (
                  <div key={index} className="question-tile">
                    <div className="grid">
                      <div className="col-10">
                        <div>
                          <strong>Question:</strong> {question.question}
                        </div>
                        <div>
                          <strong>Reason:</strong> {question.reason}
                        </div>
                      </div>
                      <div className="col-2 flex align-items-center flex-wrap">
                        <Button
                          label="Proceed"
                          onClick={() => {
                            proceedQuestion(question);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div>
                  <Button label="I am not happy, re-analyze" onClick={reanalyzePulse} />
                </div>
              </div>
            )}
            {pulseState >= PulseState.INTENT && intentQuestion != null && (
              <div>
                {pulseState === PulseState.INTENT && (
                  <h3 className="agent-label" style={{ textAlign: "left", fontSize: 16 }}>
                    <i className="fa fa-spinner fa-spin blue" />
                    &nbsp; Processing question
                  </h3>
                )}
                {pulseState >= PulseState.INTENT_READY && (
                  <h3 className="agent-label" style={{ textAlign: "left", fontSize: 16 }}>
                    <i className="fa fa-check-circle green" />
                    &nbsp; Processed question
                  </h3>
                )}
                <div className="question-tile">
                  <div>
                    <div>
                      <strong>Question:</strong> {intentQuestion.question}
                    </div>
                    <div>
                      <strong>Reason:</strong> {intentQuestion.reason}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {pulseState === PulseState.PULSE && (
              <div>
                <h3 className="agent-label" style={{ textAlign: "left", fontSize: 16 }}>
                  <i className="fa fa-spinner fa-spin blue" />
                  &nbsp; Pulse in progress
                </h3>
              </div>
            )}
            {pulseState >= PulseState.PULSE_READY && pulseResponse != null && (
              <>
                <div>
                  <h3 className="agent-label" style={{ textAlign: "left", fontSize: 16 }}>
                    <i className="fa fa-check-circle green" />
                    &nbsp; Pulse done
                  </h3>
                  <div className="question-tile">
                    <div>
                      <div>
                        <strong>Description:</strong>
                        <div className="markdown-window">
                          <MarkdownPreview source={pulseResponse?.description} />
                        </div>
                      </div>
                      <div>
                        <strong>Script:</strong>
                        <div className="script-window">
                          <pre className="text-sm">{pulseResponse?.script}</pre>
                        </div>
                      </div>
                      <div>
                        <strong>data:</strong>
                        <div className="grid">
                          <div className="col">
                            <TemporaryDataTable data={pulseResponse?.data || []} />
                          </div>
                          <div className="col">
                            {pulseResponse != null && (
                              <img
                                style={{ maxHeight: "350px" }}
                                src={`/api/pulseimg/${pulseResponse?.pulse_id}`}
                                alt="chart"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {pulseState === PulseState.PULSE_READY && (
                  <div className="publish-pulse-buttons">
                    <div className="grid align-items-center form-tile">
                      <div className="col-4 text-align-left">
                        <div>
                          <Button
                            label="Publish pulse"
                            onClick={() => {
                              publishPulse();
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-8">
                        <span>Publish pulse directly to the user facing application without scheduling pulse.</span>
                      </div>
                    </div>
                    <div className="grid align-items-center form-tile">
                      <div className="col-4 text-align-left">
                        <div>
                          <Button
                            label="Re-analyze pulse"
                            onClick={() => {
                              recreatePulse();
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-8">
                        <InputText
                          id="topic_description_recreate"
                          name="topic_description_recreate"
                          className={classNames("w-full")}
                          onChange={e => setNewTopicDescription(e.currentTarget.value)}
                          value={newTopicDescription}
                        />
                      </div>
                    </div>
                    {scheduledPulseId === "" && (
                      <div className="grid align-items-center form-tile">
                        <div className="col-4 text-align-left">
                          <div>
                            <Button
                              label="Publish and schedule"
                              onClick={() => {
                                schedulePulse();
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="cron-form-item">
                            <InputText
                              id="pulse_schedule_name"
                              name="pulse_schedule_name"
                              className={classNames("w-full")}
                              onChange={e => setScheduledPulseName(e.currentTarget.value)}
                              value={scheduledPulseName}
                            />
                          </div>
                          <div className="cron cron-form-item">
                            <Cron value={schedulePublishCron} setValue={setSchedulePublishCron} />
                          </div>
                          <div className="cron-text cron-form-item">Scheduler: {schedulePublishCron}</div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {pulseState === PulseState.PUBLISH && (
                  <div>
                    <h3 className="agent-label" style={{ textAlign: "left", fontSize: 16 }}>
                      <i className="fa fa-spinner fa-spin blue" />
                      &nbsp; Publishing pulse
                    </h3>
                  </div>
                )}
              </>
            )}
            {scheduledPulseId !== "" && pulseState >= PulseState.PUBLISH && (
              <div>
                <h3 className="agent-label" style={{ textAlign: "left", fontSize: 16 }}>
                  <i className="fa fa-check-circle green" />
                  &nbsp; Pulse scheduled ({schedulePublishCron})
                </h3>
                <div className="question-tile">
                  <div>
                    <div>
                      <strong>Pulse ID:</strong> {scheduledPulseId}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {pulseState >= PulseState.PUBLISH_READY && publishResponse != null && publishRequest != null && (
              <div>
                <h3 className="agent-label" style={{ textAlign: "left", fontSize: 16 }}>
                  <i className="fa fa-check-circle green" />
                  &nbsp; Pulse published
                </h3>
                <div className="question-tile">
                  <div>
                    <div>
                      <strong>Story: </strong>
                      <StatusMessage msg={"story_id:" + publishResponse?.story_id} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-4">
            <div className="question-tile">
              <AgentMessageTree agent={agentMsg.topic} />
              <AgentMessageTree agent={agentMsg.intent} />
              <AgentMessageTree agent={agentMsg.pulse} />
              <AgentMessageTree agent={agentMsg.publish} />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

const newPulseRoute: IAppRoute = {
  path: () => AppRoute.NewPulse,
  page: <NewPulse />,
  title: "New Pulse",
  iconClassName: "fa fa-plus",
  isAdminPage: false,
};

export default newPulseRoute;
