import { PulseQuery } from "../../../api/generated";
import DateFormat from "../../../Common/Utils/DateFormat";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { deserializePulseRequestData } from "../utils";
import { StatusMessage } from "./StatusMessage";
import { TemporaryDataTable } from "./TemporaryDataTable";

type PulseViewProps = {
    pulse : PulseQuery;
    dateTime: Date;
}

export const PulseView = ({ pulse, dateTime }: PulseViewProps) => {
  const pulseRequestData = deserializePulseRequestData(pulse?.pulse?.pulseRequest || "{}");

  return (
    <div className="content-wrapper">
        <div className="grid">
        <div className="col-12">
            <div className="question-tile">
            <div>
                <strong>Status:</strong>
                {pulse?.pulse?.pulseData === null && (
                <span>
                    <i className="fa fa-question-circle-o status-unknown"></i> Not executed
                </span>
                )}
                {pulse?.pulse?.pulseData !== null && pulse?.pulse?.pulseData?.runOk && (
                <span>
                    <i className="fa fa-check-circle-o status-ok"></i> Executed
                </span>
                )}
                {pulse?.pulse?.pulseData !== null && !pulse?.pulse?.pulseData?.runOk && (
                <span>
                    <i className="fa fa-times-circle-o status-error"></i> Failed
                </span>
                )}
            </div>
            <div>
                <strong>Date:</strong> {DateFormat.toReadableString(dateTime)}
            </div>
            <div>
                <strong>Status message:</strong>{" "}
                {!pulse?.pulse?.pulseData ? (
                "N/A"
                ) : pulse.pulse.pulseData.runOk ? (
                <StatusMessage msg={pulse?.pulse?.pulseData?.statusMessage} />
                ) : (
                pulse?.pulse?.pulseData?.statusMessage
                )}
            </div>
            </div>
            <div className="question-tile">
            <div>
                <strong>Name:</strong> {pulse?.pulse?.name}
            </div>
            <div>
                <strong>Scheduler:</strong> {pulse?.pulse?.crontab}
            </div>
            </div>
            <div className="question-tile">
            <div>
                <strong>Topic:</strong> {pulse?.pulse?.topic}
            </div>
            <div>
                <strong>Specific description:</strong> {pulseRequestData.topic_description}
            </div>
            </div>
            <div className="question-tile">
            <div>
                <strong>Question:</strong> {pulseRequestData.question}
            </div>
            <div>
                <strong>Task:</strong> {pulseRequestData.task}
            </div>
            <div>
                <strong>Reason:</strong> {pulseRequestData.reason}
            </div>
            <div>
                <strong>Data source:</strong> {pulseRequestData.data_source}
            </div>
            <div>
                <strong>Metric:</strong> {pulseRequestData.metric}
            </div>
            <div>
                <strong>Chart form:</strong> {pulseRequestData.form}
            </div>
            <div>
                <strong>Dimensions:</strong> {pulseRequestData.dimensions?.join(", ")}
            </div>
            </div>
            <div className="question-tile">
            <div>
                <div>
                <strong>Description:</strong>
                <div className="markdown-window">
                    <MarkdownPreview source={pulse?.pulse?.description ?? "[]"} />
                </div>
                </div>
                <div>
                <strong>Script:</strong>
                <div className="script-window">
                    <pre className="text-sm">{pulse?.pulse?.script}</pre>
                </div>
                </div>
            </div>
            </div>
            <h3 className="label">Pulse data</h3>
            <div className="question-tile">
            <div className="grid">
                <div className="col">
                <TemporaryDataTable data={pulse?.pulse?.pulseData?.data ?? []} />
                </div>
                <div className="col">
                <img style={{ maxHeight: "350px" }} src={`/pulseimg/${pulse.pulse?.id}`} alt="chart" />
                </div>
            </div>
            </div>
            <h3 className="label">Pulse run history</h3>
            <div className="question-tile">
            <div>
                <table className="preview-table">
                <thead>
                    <tr>
                    <th>Created</th>
                    <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {pulse?.pulse?.lastRuns?.map((run, index) => (
                    <tr key={index}>
                        <td>{DateFormat.toReadableString(dateTime)}</td>
                        <td>
                        {run.runOk ? (
                            <span>
                            <i className="fa fa-check-circle-o status-ok"></i> Executed:{" "}
                            <StatusMessage msg={run.statusMessage} />
                            </span>
                        ) : (
                            <span>
                            <i className="fa fa-times-circle-o status-error"></i> Failed: {run.statusMessage}
                            </span>
                        )}
                        </td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
            </div>
        </div>
        </div>
    </div>
  );
}
