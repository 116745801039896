import { AppRoute, IAppRoute } from "../../AppRoute";
import QuizPage from "./QuizPage";

const quizRoute: IAppRoute = {
  path: () => AppRoute.Quiz,
  page: <QuizPage />,
  title: "Quiz",
  iconClassName: "fa fa-bar-chart",
  isAdminPage: false,
};

export default quizRoute;