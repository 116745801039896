import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { MenuItem } from "primereact/menuitem";
import { TabMenu } from "primereact/tabmenu";
import { classNames } from "primereact/utils";

import { useDispatch } from "../../ducks";
import useAppNavigate from "../../hooks/useAppNavigate";
import useExpertMode from "../../hooks/useExpertMode";
import { logOut, selectors } from "../../ducks/auth";
import { getNavbarHelpItems, getNavBarItems } from "./NavBarItems";
import AccountMenu from "../AccountMenu";
import { AppRoute } from "../../AppRoute";

type Props = {
  size?: "small" | "medium" | "large" | "full";
  hideHelpMenu?: boolean;
  children?: React.ReactNode;
  hideUserMenu?: boolean;
  navigationItems?: MenuItem[];
  tabItems?: MenuItem[];
};

const defaultProps: Props = {
  size: "small",
};

function TopBar(props: Props) {
  const { size, children, hideUserMenu, navigationItems, tabItems, hideHelpMenu = false } = {
    ...defaultProps,
    ...props,
  };

  //const userProfile = useSelector(selectors.selectUserProfile);
  const isAdmin = useSelector(selectors.isAdministrator);
  //const config = useSelector(selectors.selectUiConfig);
  const { isExpertModeSet, toggleExpertMode } = useExpertMode();

  const company = "BiBro";
  const upn = "User"

  const dispatch = useDispatch();
  const navigate = useAppNavigate();
  const path = useLocation();

  const onLogoClick = React.useCallback(() => {
    // navigate(AppRoute.Home);
  }, [navigate]);

  const onLogout = React.useCallback(() => dispatch(logOut()), [dispatch]);
  const onExpertMode = React.useCallback(toggleExpertMode, [toggleExpertMode]);

  const navItems: MenuItem[] = React.useMemo(
    () =>
      navigationItems ? 
      [ ...navigationItems, ...(hideHelpMenu ? [] : getNavbarHelpItems(upn, company))]
      : [
        ...getNavBarItems(isAdmin, isExpertModeSet, navigate, path.pathname),
        ...(hideHelpMenu ? [] : getNavbarHelpItems(upn, company)),
      ],
    [hideHelpMenu, isAdmin, isExpertModeSet, navigate, navigationItems, path.pathname]
  );

  return (
    <div
      className={classNames("top-bar", {
        "top-bar-medium": size === "medium",
        "top-bar-large": size === "large",
        "top-bar-full": size === "full",
      })}
    >
      <div className="background-container">
        <div className="mask-left" />
        <div className="mask-right" />
      </div>

      <div className="top-bar-navigation">
        <div className="top-bar-left">
          <img src="/img/bibro-logo.webp" alt="BiBro" className="app-logo cursor-pointer mr-4" onClick={onLogoClick} />
          <Menubar model={navItems} />
        </div>

        {!hideUserMenu && (
          <div className="top-bar-right">
            <AccountMenu
              userName={"User"}
              isExpertModeSet={isExpertModeSet}
              isAdministrator={isAdmin}
              onLogout={onLogout}
              onExpertMode={onExpertMode}
            />
          </div>
        )}
      </div>

      <div className="top-bar-content">{children}</div>
      {tabItems && <TabMenu model={tabItems} />}
    </div>
  );
}

export default TopBar;
