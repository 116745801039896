import { Avatar } from "primereact/avatar";
import { useMemo } from "react";
import { quizCategories, QuizCategoryType } from "../../questionsData";
import { QuestionDefinition } from "../../types";

type QuizInfoContentProps = {
    question: QuestionDefinition;
    questionIndex: number;
    topic: QuizCategoryType;
}

export const QuizInfoContent = ({ question: { question }, questionIndex, topic }: QuizInfoContentProps) => {
    const { title: categoryName } = useMemo(() => quizCategories.find((category) => category.id === topic)!, [topic]);

    return (
        <div className="tenant">
            <div>
                <Avatar shape="circle" size="large" label={`#${questionIndex + 1}`} />
            </div>
        <div>
            <div className="company">{question}</div>
            <div className="domain">{categoryName}</div>
        </div>
    </div>
    );
}