export const TemporaryDataTable = (data: any) => {
    try {
      const tmp_jsondata = JSON.parse(data.data);
      const jsondata = tmp_jsondata.data;
      if (!jsondata || !Array.isArray(jsondata) || jsondata.length === 0) {
        return <div>Empty dataset</div>;
      }
      console.log("jsondata*2");
      // expecting that data is array of objects
      // get column names from first row
      const firstRow = jsondata[0];
      return (
        <div>
          <table className="preview-table">
            <thead>
              <tr>
                {Object.keys(firstRow).map((key, index) => (
                  <th key={index}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {jsondata.map((row: any, index: number) => (
                <tr key={index}>
                  {Object.keys(row).map((key, index) => (
                    <td key={index}>{row[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } catch (e) {
      return <div>Empty dataset</div>;
    }
}