// generic websocket message
export class WebSocketMessage {
  channel: string;
  data: any;

  constructor(channel: string, data: any) {
    this.channel = channel;
    this.data = data;
  }
}

// generic response data from websocket response, it goes in WebSocketMessage.data
export class WebSocketResponseData {
  type: string;
  correlation_id: string;
  data: any;

  constructor(type: string, correlation_id: string, data: any) {
    this.type = type;
    this.correlation_id = correlation_id;
    this.data = data;
  }
}

// "event" websocket response - goes in WebSocketResponseData.data
export class WebSocketResponseDataEvent {
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}

// error websocket response - goes in WebSocketResponseData.data
// we don't need special class because data is just a string

// "topic" websocket response - goes in WebSocketResponseData.data
// -> in data will come [] of WebSocketMessageTopicResponseDataItem use only 0th item
export class WebSocketMessageTopicResponseDataItem {
  questions: WebSocketMessageTopicResponseQuestion[];

  constructor(questions: WebSocketMessageTopicResponseQuestion[]) {
    this.questions = questions;
  }
}
export class WebSocketMessageTopicResponseQuestion {
  question: string;
  metric: string;
  reason: string;
  data_source: string;
  topic_description: string;

  constructor(
    question: string,
    metric: string,
    reason: string,
    data_source: string,
    topic_description: string
  ) {
    this.question = question;
    this.metric = metric;
    this.reason = reason;
    this.data_source = data_source;
    this.topic_description = topic_description;
  }
}

// "intent" websocket response - goes in WebSocketResponseData.data
// -> in data will come [] of WebSocketMessageIntentResponseDataItem use only 0th item
export class WebSocketMessageIntentResponseDataItem {
  task: string;
  form: string;
  dimensions: string[];
  metric: string;
  question: string;
  reason: string;
  data_source: string;
  topic_description: string;
  visualization_description: string;
  data_format: string;

  constructor(
    task: string,
    form: string,
    dimensions: string[],
    metric: string,
    question: string,
    reason: string,
    data_source: string,
    topic_description: string,
    visualization_description: string,
    data_format: string
  ) {
    this.task = task;
    this.form = form;
    this.dimensions = dimensions;
    this.metric = metric;
    this.question = question;
    this.reason = reason;
    this.data_source = data_source;
    this.topic_description = topic_description;
    this.visualization_description = visualization_description;
    this.data_format = data_format;
  }
}

// "pulse" websocket response - goes in WebSocketResponseData.data
export class WebSocketMessagePulseResponseData {
  script: string;
  description: string;
  data: any;
  pulse_id: string;

  constructor(script: string, description: string, data: any, pulse_id: string) {
    this.script = script;
    this.description = description;
    this.data = data;
    this.pulse_id = pulse_id;
  }
}

// "publish" websocket response - goes in WebSocketResponseData.data
export class WebSocketMessagePublishResponseData {
  story_id: string;

  constructor(story_id: string) {
    this.story_id = story_id;
  }
}

// ##############################
// REQUESTS
// ##############################

// "topic" websocket request - goes to WebSocketMessage.data
export class WebSocketMessageTopicRequest {
  correlation_id: string;
  topic: string;
  description: string;
  count: number;

  constructor(
    correlation_id: string,
    topic: string,
    description: string,
    count: number
  ) {
    this.correlation_id = correlation_id;
    this.topic = topic;
    this.description = description;
    this.count = count;
  }
}

// "intent" websocket request - goes to WebSocketMessage.data
export class WebSocketMessageIntentRequest {
  correlation_id: string;
  questions: WebSocketMessageIntentRequestQuestion[];

  constructor(
    correlation_id: string,
    questions: WebSocketMessageIntentRequestQuestion[]
  ) {
    this.correlation_id = correlation_id;
    this.questions = questions;
  }
}
export class WebSocketMessageIntentRequestQuestion {
  question: string;
  metric: string;
  reason: string;
  data_source: string;
  topic_description: string;

  constructor(
    question: string,
    metric: string,
    reason: string,
    data_source: string,
    topic_description: string
  ) {
    this.question = question;
    this.metric = metric;
    this.reason = reason;
    this.data_source = data_source;
    this.topic_description = topic_description;
  }
}

// "pulse" websocket request - goes to WebSocketMessage.data
export class WebSocketMessagePulseRequest {
  correlation_id: string;
  task: string;
  form: string;
  dimensions: string[];
  metric: string;
  question: string;
  reason: string;
  data_source: string;
  topic: string;
  topic_description: string;
  visualization_description: string;
  data_format: string;

  constructor(
    correlation_id: string,
    task: string,
    form: string,
    dimensions: string[],
    metric: string,
    question: string,
    reason: string,
    data_source: string,
    topic: string,
    topic_description: string,
    visualization_description: string,
    data_format: string
  ) {
    this.correlation_id = correlation_id;
    this.task = task;
    this.form = form;
    this.dimensions = dimensions;
    this.metric = metric;
    this.question = question;
    this.reason = reason;
    this.data_source = data_source;
    this.topic = topic;
    this.topic_description = topic_description;
    this.visualization_description = visualization_description;
    this.data_format = data_format;
  }
}

// "publish" websocket request - goes to WebSocketMessage.data
export class WebSocketMessagePublishRequest {
  pulse_input: WebSocketMessagePulseRequest;
  pulse_result: WebSocketMessagePulseResponseData;

  constructor(
    pulse_input: WebSocketMessagePulseRequest,
    pulse_result: WebSocketMessagePulseResponseData
  ) {
    this.pulse_input = pulse_input;
    this.pulse_result = pulse_result;
  }
}
