import { Avatar } from "primereact/avatar";
import { Tag } from "primereact/tag";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../AppRoute";
import TopBar from "../../components/TopBar";

type CustomBarProps = {
  onEdit: () => void;
  onDelete: () => void;
  pulseNotFound: boolean;
};

function PulseDetailCustomBar(props: CustomBarProps) {
  const navigate = useNavigate();
  const navigationItemsPage = [
    {
      label: "Edit",
      icon: "fa-regular fa-pencil",
      command: () => props.onEdit(),
    },
    {
      label: "Delete",
      icon: "fa-regular fa-trash",
      command: () => props.onDelete(),
    },
  ];
  const navigationBack = [
    {
      label: "Back to pulses",
      icon: "fa-regular fa-long-arrow-left",
      command: () => navigate(AppRoute.Pulse),
    },
  ];

  const filterPageItems = () => {
    if (props.pulseNotFound) {
      return [];
    }
    return navigationItemsPage;
  };

  const navigationItems = [...navigationBack, ...filterPageItems()];

  return (
    <TopBar hideUserMenu size="small" navigationItems={navigationItems}>
    </TopBar>
  );
}

export default PulseDetailCustomBar;