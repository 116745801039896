import { QuestionDefinition } from "../types";
import { QuizCategoryType } from "./categories";
import { financeQuestion1, financeQuestion2 } from "./finance";
import { legoQuestion1 } from "./lego/question1";
import { legoQuestion2 } from "./lego/question2";
import { movieQuestion1, movieQuestion2 } from "./movies";
import { olympicsQuestion1, olympicsQuestion2 } from "./olympics";

export const allQuestions : Record<QuizCategoryType, QuestionDefinition[]> = {
    [QuizCategoryType.Movies]: [ movieQuestion1, movieQuestion2 ],
    [QuizCategoryType.LEGO]: [ legoQuestion1, legoQuestion2 ],
    [QuizCategoryType.Olympics]: [ olympicsQuestion1, olympicsQuestion2 ],
    [QuizCategoryType.Finance]: [ financeQuestion1, financeQuestion2 ],
};