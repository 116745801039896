// extract URL for websocket connection from URL of the page,

import { useEffect, useRef, useState } from "react";
import { WebSocketMessage } from "./dataTypes";

// take into account also :port
export function getWebSocketUrl(clientId: string): string {
  const loc = window.location;
  const url = (loc.protocol === "https:" ? "wss:" : "ws:") + "//" + loc.host + "/api/ws/" + clientId;
  // for DEV replace ://localhost:3000 with ://localhost:8000
  return url.replace("://localhost:3000", "://localhost:8000");
}

export function getWebSocketErrorMessage(code: number): string {
  switch (code) {
    case 1000:
      return "Normal closure";
    case 1001:
      return "Going away";
    case 1002:
      return "Protocol error";
    case 1003:
      return "Unsupported data";
    case 1005:
      return "No status code";
    case 1006:
      return "Connection lost";
    case 1007:
      return "Invalid frame payload data";
    case 1008:
      return "Policy violation";
    case 1009:
      return "Message too big";
    case 1010:
      return "Missing extension";
    case 1011:
      return "Internal error";
    case 1012:
      return "Service restart";
    case 1013:
      return "Try again later";
    case 1015:
      return "TLS handshake failure";
    default:
      return "Unknown error";
  }
}

export const useWebSocket = ({
  correlation_id,
}: {
  correlation_id: string;
}): [boolean, string, WebSocketMessage | null, ((data: WebSocketMessage) => void) | null] => {
  const [isReady, setIsReady] = useState(false);
  const [message, setMessage] = useState<WebSocketMessage | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const ws = useRef<WebSocket | null>(null);

  useEffect(() => {
    if (correlation_id !== "") {
      const url = getWebSocketUrl(correlation_id);
      let socket = null as WebSocket | null;
      const connectWebSocket = () => {
        socket = new WebSocket(url);
        socket.onopen = () => {
          setIsReady(true);
          setErrorMessage("");
        };
        socket.onclose = event => {
          setIsReady(false);
          setErrorMessage(getWebSocketErrorMessage(event.code));
          // try to reconnect after 1000ms
          setTimeout(connectWebSocket, 1000);
          socket?.close();
        };
        socket.onmessage = event => {
          setMessage(JSON.parse(event.data));
        };
        ws.current = socket;
      };

      connectWebSocket();

      return () => {
        if (socket) {
          socket.close();
        }
      };
    }
  }, [correlation_id]);

  // bind is needed to make sure `send` references correct `this`
  const sendMessage = async (data: WebSocketMessage) => {
    if (ws.current) {
      const jsonData = JSON.stringify(data);
      ws.current.send(jsonData);
    }
  };

  return [isReady, errorMessage, message, sendMessage];
};
