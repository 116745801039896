export const StatusMessage = (props: { msg: string | null | undefined }) => {
  // "[teams](https://teams.microsoft.com/l/message/19:ba80e44a277d4da8acaa9a83fa896014@thread.tacv2/1713993297218)"
  // name is "teams"
  // link is "https://teams.microsoft.com/l/message/19:ba80e44a277d4da8acaa9a83fa896014@thread.tacv2/1713993297218"
  // link should be encoded
  const parseName = (link: string) => {
    // parse name from [] brackets
    try {
      const name = link.split("[")[1].split("]")[0];
      return name;
    } catch (e) {
      return "";
    }
  };
  const parseUrl = (link: string) => {
    // parse url from () brackets
    try {
      const url = link.split("(")[1].split(")")[0];
      // url encode
      return url;
    } catch (e) {
      return "";
    }
  };
  if (!props.msg) {
    return <span>N/A</span>;
  }
  if (props.msg.startsWith("story_id:")) {
    const storyId = props.msg.split("story_id:")[1].trim();
    const links = storyId.split(";");
    return (
      <span>
        {links.map((link, index) => (
          <>
            <a href={`${parseUrl(link)}`} target="_blank" rel="noreferrer">
              <i className="fa fa-external-link" style={{ fontSize: "10px" }}></i> {parseName(link)}
            </a>{" "}
            &nbsp;{" "}
          </>
        ))}
      </span>
    );
  }
  return <span>{props.msg}</span>;
}
