import Tile, { TileSize } from "../../../Dashboard/components/Tile";
import { QuestionDefinition, QUIZ_QUESTION_COUNT } from "../../types";
import { QuizCategoryType } from "../../questionsData";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";

export type QuizMainScreenProps = {
    question: QuestionDefinition;
    questionIndex: number;
    topic: QuizCategoryType;
    showBiBroPulse: () => void;
    markAnswer: (answerIndex: number) => void;
    selectedAnswer: number | null;
    goToNextAnswer: () => void;
    goToSummary: () => void;
    isLoading: boolean;
};

const answerLetters = ["a", "b", "c", "d"];

const getTileStateClass = (index: number, selectedAnswer: number | null, correctAnswerIndex: number) => {
    if(selectedAnswer === null) return "";
    if(index !== selectedAnswer && index !== correctAnswerIndex) return "faded";
    if(index === correctAnswerIndex) return "correct";
    if(index === selectedAnswer) return "incorrect";
}

export const QuizMainScreen = ({
    question: { answers, biBroResponse, explanation, graphComponent, correctAnswerIndex },
    questionIndex,
    showBiBroPulse,
    markAnswer,
    selectedAnswer,
    goToNextAnswer,
    goToSummary,
    isLoading,
}: QuizMainScreenProps) => {

    return (
        <>
            <div className="quiz-wrapper">
                <div className="tiles answers">
                    <div className={`tiles-stack answers ${selectedAnswer !== null ? 'answered' : ''}`}>
                        {answers.map((answer, index) => (
                            <Tile className={getTileStateClass(index, selectedAnswer, correctAnswerIndex)} key={answer} size={TileSize.Small} onClick={() => { markAnswer(index) }}>
                                    <div className="counter-tile">
                                        <div className="title"><strong><i>{answerLetters[index]})</i>&nbsp;</strong> {answer}</div>
                                    </div>
                            </Tile>
                        ))}
                    </div>
                    
                </div>
                <div className="quiz-right">
                    {(selectedAnswer !== null) && (
                        <>
                            <div className="explanation-title">Vysvětlení od <b>BI BRO</b></div>
                            
                            {isLoading && (
                                <i className="load-spinner fa fa-refresh" aria-hidden="true"></i>
                            )}
                            
                            {!isLoading && (
                                <>
                                    <div className="explanation">{explanation}</div>
                                    {graphComponent !== null && <div className="explanation-graph">{graphComponent}</div>}
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className="quiz-buttons">
                    {(biBroResponse !== undefined && selectedAnswer !== null && !isLoading) && (
                        <Button label="Zobrazit BiBro Pulse &nbsp;" icon="fa fa-file-text" iconPos="right" onClick={showBiBroPulse} />
                    )}
                    {(selectedAnswer !== null && questionIndex !== QUIZ_QUESTION_COUNT - 1 && !isLoading) && (
                        <Button label="Další otázka &nbsp;" className="p-button-secondary" icon="fa fa-arrow-circle-right" iconPos="right" onClick={goToNextAnswer} />
                    )}
                    {(selectedAnswer !== null && questionIndex === QUIZ_QUESTION_COUNT - 1 && !isLoading) && (
                        <Button label="Dokončit kvíz &nbsp;" className="p-button-secondary" icon="fa fa-arrow-circle-right" iconPos="right" onClick={goToSummary} />
                    )}
                </div>
            </div>
        </>
    )
}