import { useParams } from "react-router-dom";
import { AppRoute, IAppRoute } from "../../AppRoute";
import Page from "../../components/Page";
import PulseDetailCustomBar from "./PulseDetailCustomBar";
import { PulseQuery, useDeletePulseMutation, usePulseQuery } from "../../api/generated";
import useAppNavigate from "../../hooks/useAppNavigate";
import useToast from "../../hooks/useToast";
import { RequestError } from "../../api/graphqlBaseQueryTypes";
import { useEffect, useState } from "react";
import HeaderPanel from "../../components/HeaderPanel";
import PulseEditor from "./PulseEditor";
import DeleteDialog from "../../components/DeleteDialog";
import MarkdownPreview from "@uiw/react-markdown-preview";
import DateFormat from "../../Common/Utils/DateFormat";
import WarningContent from "../../components/WarningContent";
import { SerializedError } from "@reduxjs/toolkit";

class PulseRequestData {
  correlation_id: string;
  task: string;
  form: string;
  dimensions: string[];
  metric: string;
  question: string;
  reason: string;
  data_source: string;
  topic: string;
  topic_description: string;

  constructor(data: any) {
    this.correlation_id = data.correlation_id;
    this.task = data.task;
    this.form = data.form;
    this.dimensions = data.dimensions;
    this.metric = data.metric;
    this.question = data.question;
    this.reason = data.reason;
    this.data_source = data.data_source;
    this.topic = data.topic;
    this.topic_description = data.topic_description;
  }
}

function PulseDetail() {
  const params = useParams<{ id: string }>();
  const navigate = useAppNavigate();
  const toast = useToast();

  const refetchPulse = () => {};
  const pulse = undefined as PulseQuery | undefined;
  const pulseIsError = false;
  const pulseError = undefined as unknown as RequestError | SerializedError | undefined;

  /*const {
    data: pulse,
    refetch: refetchPulse,
    isError: pulseIsError,
    error: pulseError,
  } = usePulseQuery({ id: params.id || "" });*/
  const [errorNotFound, setErrorNotFound] = useState<boolean>(false);
  const [deletePulse] = useDeletePulseMutation();
  const [editedPulseId, setEditedPulseId] = useState<string | null>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);

  function deserializePulseRequestData(jsonString: string): PulseRequestData {
    const jsonObject = JSON.parse(jsonString);
    return new PulseRequestData(jsonObject);
  }

  const pulseRequestData = deserializePulseRequestData(pulse?.pulse?.pulseRequest || "{}");

  useEffect(() => {
    if (pulseIsError) {
      let e = pulseError as RequestError;
      let emsg = "An unknown error occurred while loading the pulse settings";
      if (e.errors.length > 0) {
        emsg = e.errors[0].message;
      }
      if (emsg.endsWith(" not found")) {
        setErrorNotFound(true);
      } else {
        toast.show({
          severity: "error",
          detail: emsg,
          life: 4000,
        });
      }
    } else {
      setErrorNotFound(false);
    }
  }, [pulseIsError, pulseError, toast]);

  const handleEdit = () => {
    setEditedPulseId(params.id || "");
  };

  const handleDelete = () => {
    setDeleteDialogVisible(true);
  };

  const onEditClose = (refresh: boolean) => {
    setEditedPulseId(null);
    if (refresh) {
      refetchPulse();
    }
  };

  const actionDeletePulse = async () => {
    try {
      await deletePulse({ id: params.id || "" }).unwrap();
      toast.show({
        severity: "success",
        detail: `The pulse has been deleted successfully.`,
        life: 4000,
      });
      navigate(AppRoute.Pulse);
    } catch (ex) {
      let e = ex as RequestError;
      let emsg = "An unknown error occurred while deleting the pulse";
      if (e.errors.length > 0) {
        emsg = e.errors[0].message;
      }
      toast.show({
        severity: "error",
        detail: emsg,
        life: 4000,
      });
    }
  };

  function TemporaryDataTable(data: any) {
    try {
      const tmp_jsondata = JSON.parse(data.data);
      const jsondata = tmp_jsondata.data;
      if (!jsondata || !Array.isArray(jsondata) || jsondata.length === 0) {
        return <div>Empty dataset</div>;
      }
      console.log("jsondata*2");
      // expecting that data is array of objects
      // get column names from first row
      const firstRow = jsondata[0];
      return (
        <div>
          <table className="preview-table">
            <thead>
              <tr>
                {Object.keys(firstRow).map((key, index) => (
                  <th key={index}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {jsondata.map((row: any, index: number) => (
                <tr key={index}>
                  {Object.keys(row).map((key, index) => (
                    <td key={index}>{row[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } catch (e) {
      return <div>Empty dataset</div>;
    }
  }

  function StatusMessage(props: { msg: string | null | undefined }) {
    // "[teams](https://teams.microsoft.com/l/message/19:ba80e44a277d4da8acaa9a83fa896014@thread.tacv2/1713993297218)"
    // name is "teams"
    // link is "https://teams.microsoft.com/l/message/19:ba80e44a277d4da8acaa9a83fa896014@thread.tacv2/1713993297218"
    // link should be encoded
    const parseName = (link: string) => {
      // parse name from [] brackets
      try {
        const name = link.split("[")[1].split("]")[0];
        return name;
      } catch (e) {
        return "";
      }
    };
    const parseUrl = (link: string) => {
      // parse url from () brackets
      try {
        const url = link.split("(")[1].split(")")[0];
        // url encode
        return url;
      } catch (e) {
        return "";
      }
    };
    if (!props.msg) {
      return <span>N/A</span>;
    }
    if (props.msg.startsWith("story_id:")) {
      const storyId = props.msg.split("story_id:")[1].trim();
      const links = storyId.split(";");
      return (
        <span>
          {links.map((link, index) => (
            <>
              <a href={`${parseUrl(link)}`} target="_blank" rel="noreferrer">
                <i className="fa fa-external-link" style={{ fontSize: "10px" }}></i> {parseName(link)}
              </a>{" "}
              &nbsp;{" "}
            </>
          ))}
        </span>
      );
    }
    return <span>{props.msg}</span>;
  }

  return (
    <Page
      windowTitle={pulseDetailRoute.title}
      className="page-detail-pulse"
      topBar={<PulseDetailCustomBar onEdit={handleEdit} onDelete={handleDelete} pulseNotFound={errorNotFound} />}
    >
      <HeaderPanel titleText={pulseDetailRoute.title}></HeaderPanel>

      {errorNotFound ? (
        <WarningContent title="Pulse Not Found" content="This pulse does not exist." />
      ) : (
        <div className="content-wrapper">
          <div className="grid">
            <div className="col-12">
              <div className="question-tile">
                <div>
                  <strong>Status:</strong>
                  {pulse?.pulse?.pulseData === null && (
                    <span>
                      <i className="fa fa-question-circle-o status-unknown"></i> Not executed
                    </span>
                  )}
                  {pulse?.pulse?.pulseData !== null && pulse?.pulse?.pulseData?.runOk && (
                    <span>
                      <i className="fa fa-check-circle-o status-ok"></i> Executed
                    </span>
                  )}
                  {pulse?.pulse?.pulseData !== null && !pulse?.pulse?.pulseData?.runOk && (
                    <span>
                      <i className="fa fa-times-circle-o status-error"></i> Failed
                    </span>
                  )}
                </div>
                <div>
                  <strong>Date:</strong> {DateFormat.toReadableString(pulse?.pulse?.pulseData?.created)}
                </div>
                <div>
                  <strong>Status message:</strong>{" "}
                  {!pulse?.pulse?.pulseData ? (
                    "N/A"
                  ) : pulse.pulse.pulseData.runOk ? (
                    <StatusMessage msg={pulse?.pulse?.pulseData?.statusMessage} />
                  ) : (
                    pulse?.pulse?.pulseData?.statusMessage
                  )}
                </div>
              </div>
              <div className="question-tile">
                <div>
                  <strong>Name:</strong> {pulse?.pulse?.name}
                </div>
                <div>
                  <strong>Scheduler:</strong> {pulse?.pulse?.crontab}
                </div>
              </div>
              <div className="question-tile">
                <div>
                  <strong>Topic:</strong> {pulse?.pulse?.topic}
                </div>
                <div>
                  <strong>Specific description:</strong> {pulseRequestData.topic_description}
                </div>
              </div>
              <div className="question-tile">
                <div>
                  <strong>Question:</strong> {pulseRequestData.question}
                </div>
                <div>
                  <strong>Task:</strong> {pulseRequestData.task}
                </div>
                <div>
                  <strong>Reason:</strong> {pulseRequestData.reason}
                </div>
                <div>
                  <strong>Data source:</strong> {pulseRequestData.data_source}
                </div>
                <div>
                  <strong>Metric:</strong> {pulseRequestData.metric}
                </div>
                <div>
                  <strong>Chart form:</strong> {pulseRequestData.form}
                </div>
                <div>
                  <strong>Dimensions:</strong> {pulseRequestData.dimensions?.join(", ")}
                </div>
              </div>
              <div className="question-tile">
                <div>
                  <div>
                    <strong>Description:</strong>
                    <div className="markdown-window">
                      <MarkdownPreview source={pulse?.pulse?.description ?? "[]"} />
                    </div>
                  </div>
                  <div>
                    <strong>Script:</strong>
                    <div className="script-window">
                      <pre className="text-sm">{pulse?.pulse?.script}</pre>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="label">Pulse data</h3>
              <div className="question-tile">
                <div className="grid">
                  <div className="col">
                    <TemporaryDataTable data={pulse?.pulse?.pulseData?.data ?? []} />
                  </div>
                  <div className="col">
                    <img style={{ maxHeight: "350px" }} src={`/api/pulseimg/${params.id}`} alt="chart" />
                  </div>
                </div>
              </div>
              <h3 className="label">Pulse run history</h3>
              <div className="question-tile">
                <div>
                  <table className="preview-table">
                    <thead>
                      <tr>
                        <th>Created</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pulse?.pulse?.lastRuns?.map((run, index) => (
                        <tr key={index}>
                          <td>{DateFormat.toReadableString(run.created)}</td>
                          <td>
                            {run.runOk ? (
                              <span>
                                <i className="fa fa-check-circle-o status-ok"></i> Executed:{" "}
                                <StatusMessage msg={run.statusMessage} />
                              </span>
                            ) : (
                              <span>
                                <i className="fa fa-times-circle-o status-error"></i> Failed: {run.statusMessage}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DeleteDialog
        message={
          <>
            Are you sure you want to delete pulse <span className="font-bold">{pulse?.pulse?.name}</span>?<br />
            This action can not be undone.
          </>
        }
        header="Delete Pulse"
        accept={actionDeletePulse}
        onHide={() => setDeleteDialogVisible(false)}
        visible={deleteDialogVisible}
      />
    </Page>
  );
}

const pulseDetailRoute: IAppRoute = {
  title: "Pulse detail",
  path: () => AppRoute.PulseDetail,
  page: <PulseDetail />,
  iconClassName: "fa-regular fa-bar-chart",
  isAdminPage: false,
  isHidden: true,
};

export default pulseDetailRoute;
