import { PulseQuery } from "../../api/generated";

export type QuizState = "selectScreen" | "quizScreen" | "pusleScreen" | "resultScreen";
export type QuestionDefinition = {
    question: string;
    answers: string[];
    correctAnswerIndex: number;
    explanation: React.ReactNode | string;
    graphComponent?: React.ReactNode;
    biBroResponse?: { data: PulseQuery };
}

export const QUIZ_QUESTION_COUNT = 2;

export class PulseRequestData {
    correlation_id: string;
    task: string;
    form: string;
    dimensions: string[];
    metric: string;
    question: string;
    reason: string;
    data_source: string;
    topic: string;
    topic_description: string;
  
    constructor(data: any) {
      this.correlation_id = data.correlation_id;
      this.task = data.task;
      this.form = data.form;
      this.dimensions = data.dimensions;
      this.metric = data.metric;
      this.question = data.question;
      this.reason = data.reason;
      this.data_source = data.data_source;
      this.topic = data.topic;
      this.topic_description = data.topic_description;
    }
}
