import { api } from './graphApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date with time (isoformat) */
  DateTime: any;
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type DashboardData = {
  __typename?: 'DashboardData';
  statistics: Array<DashboardStatistic>;
  tiles: DashboardTiles;
};

export type DashboardStatistic = {
  __typename?: 'DashboardStatistic';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type DashboardTiles = {
  __typename?: 'DashboardTiles';
  failedPulses: Scalars['Int'];
  pulses: Scalars['Int'];
  pulsesExecutedToday: Scalars['Int'];
  pulsesScheduledToday: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addPulse: Pulse;
  deletePulse: Pulse;
  inactivatePulse: Pulse;
  updatePulse: Pulse;
};


export type MutationAddPulseArgs = {
  pulse: PulseAddInput;
};


export type MutationDeletePulseArgs = {
  pulseId: Scalars['String'];
};


export type MutationInactivatePulseArgs = {
  pulseId: Scalars['String'];
};


export type MutationUpdatePulseArgs = {
  updatePulse: PulseUpdateInput;
};

export type Pulse = {
  __typename?: 'Pulse';
  crontab?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastRuns?: Maybe<Array<PulseData>>;
  name: Scalars['String'];
  pulseData?: Maybe<PulseData>;
  pulseRequest?: Maybe<Scalars['String']>;
  script?: Maybe<Scalars['String']>;
  topic: Scalars['String'];
};

export type PulseAddInput = {
  crontab?: InputMaybe<Scalars['String']>;
  dataJson?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  pulseRequest?: InputMaybe<Scalars['String']>;
  script?: InputMaybe<Scalars['String']>;
  topic: Scalars['String'];
};

export type PulseData = {
  __typename?: 'PulseData';
  created: Scalars['DateTime'];
  data?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  runOk: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type PulseUpdateInput = {
  crontab?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  topic: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  channels: Array<Channel>;
  dashboardData: DashboardData;
  pulse?: Maybe<Pulse>;
  pulses: Array<Pulse>;
};


export type QueryPulseArgs = {
  pulseId: Scalars['String'];
};

export type ChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type ChannelsQuery = { __typename?: 'Query', channels: Array<{ __typename?: 'Channel', id: string, name: string }> };

export type DashboardDataQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardDataQuery = { __typename?: 'Query', dashboardData: { __typename?: 'DashboardData', tiles: { __typename?: 'DashboardTiles', failedPulses: number, pulses: number, pulsesExecutedToday: number, pulsesScheduledToday: number }, statistics: Array<{ __typename?: 'DashboardStatistic', label: string, value: number }> } };

export type DeletePulseMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePulseMutation = { __typename?: 'Mutation', deletePulse: { __typename?: 'Pulse', id: string } };

export type PulseQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PulseQuery = { __typename?: 'Query', pulse?: { __typename?: 'Pulse', id: string, name: string, crontab?: string | null, description?: string | null, topic: string, script?: string | null, pulseRequest?: string | null, pulseData?: { __typename?: 'PulseData', created: any, id: string, runOk: boolean, statusMessage?: string | null, data?: string | null } | null, lastRuns?: Array<{ __typename?: 'PulseData', created: any, id: string, runOk: boolean, statusMessage?: string | null }> | null } | null };

export type PulsesQueryVariables = Exact<{ [key: string]: never; }>;


export type PulsesQuery = { __typename?: 'Query', pulses: Array<{ __typename?: 'Pulse', id: string, name: string, crontab?: string | null, description?: string | null, topic: string, pulseData?: { __typename?: 'PulseData', id: string, created: any, statusMessage?: string | null, runOk: boolean } | null }> };

export type UpdatePulseMutationVariables = Exact<{
  updatePulse: PulseUpdateInput;
}>;


export type UpdatePulseMutation = { __typename?: 'Mutation', updatePulse: { __typename?: 'Pulse', id: string } };


export const ChannelsDocument = `
    query Channels {
  channels {
    id
    name
  }
}
    `;
export const DashboardDataDocument = `
    query DashboardData {
  dashboardData {
    tiles {
      failedPulses
      pulses
      pulsesExecutedToday
      pulsesScheduledToday
    }
    statistics {
      label
      value
    }
  }
}
    `;
export const DeletePulseDocument = `
    mutation DeletePulse($id: String!) {
  deletePulse(pulseId: $id) {
    id
  }
}
    `;
export const PulseDocument = `
    query Pulse($id: String!) {
  pulse(pulseId: $id) {
    id
    name
    crontab
    description
    topic
    script
    pulseRequest
    pulseData {
      created
      id
      runOk
      statusMessage
      data
    }
    lastRuns {
      created
      id
      runOk
      statusMessage
    }
  }
}
    `;
export const PulsesDocument = `
    query Pulses {
  pulses {
    id
    name
    crontab
    description
    topic
    pulseData {
      id
      created
      statusMessage
      runOk
    }
  }
}
    `;
export const UpdatePulseDocument = `
    mutation UpdatePulse($updatePulse: PulseUpdateInput!) {
  updatePulse(updatePulse: $updatePulse) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    Channels: build.query<ChannelsQuery, ChannelsQueryVariables | void>({
      query: (variables) => ({ document: ChannelsDocument, variables })
    }),
    DashboardData: build.query<DashboardDataQuery, DashboardDataQueryVariables | void>({
      query: (variables) => ({ document: DashboardDataDocument, variables })
    }),
    DeletePulse: build.mutation<DeletePulseMutation, DeletePulseMutationVariables>({
      query: (variables) => ({ document: DeletePulseDocument, variables })
    }),
    Pulse: build.query<PulseQuery, PulseQueryVariables>({
      query: (variables) => ({ document: PulseDocument, variables })
    }),
    Pulses: build.query<PulsesQuery, PulsesQueryVariables | void>({
      query: (variables) => ({ document: PulsesDocument, variables })
    }),
    UpdatePulse: build.mutation<UpdatePulseMutation, UpdatePulseMutationVariables>({
      query: (variables) => ({ document: UpdatePulseDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChannelsQuery, useLazyChannelsQuery, useDashboardDataQuery, useLazyDashboardDataQuery, useDeletePulseMutation, usePulseQuery, useLazyPulseQuery, usePulsesQuery, useLazyPulsesQuery, useUpdatePulseMutation } = injectedRtkApi;

