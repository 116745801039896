import homeRoute from "./pages/Home";
import newPulseRoute from "./pages/NewPulse";
import pulsesRoute from "./pages/Pulse";
import pulseDetailRoute from "./pages/Pulse/PulseDetail";
import quizRoute from "./pages/Quiz/QuizRoute";

export enum AppRoute {
  Quiz = "/",
  Home = "/home",
  Help = "/help",
  NewPulse = "/pulse-new",
  Pulse = "/pulse",
  PulseDetail = "/pulse/:id",
}
export interface IAppRoute {
  title: string;
  iconClassName?: string;
  path: () => AppRoute;
  page: JSX.Element;
  subRoutes?: IAppRoute[];
  isAdminPage?: boolean;
  isExpertMode?: boolean;
  isHidden?: boolean;
}

export const appRoutes: IAppRoute[] = [
  quizRoute,
  homeRoute,
  pulsesRoute,
  newPulseRoute,
  pulseDetailRoute,
];