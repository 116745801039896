import HeaderTile from "../../../Dashboard/components/HeaderTile";
import { QuizCategoryType, quizCategories } from "../../questionsData";

type TopicSelectContentProps = {
    onTopicSelect: (category: QuizCategoryType) => void;
}

export const TopicSelectContent = ({ onTopicSelect }: TopicSelectContentProps) => 
    <>
        <div className="title text-white">
            <i className="fa fa-hand-spock-o pr-2" />&nbsp;
            Vítejte, zkuste porazit naše <b>BI BRO AI</b> v kvízu
        </div>
        <div className="info">Vyberte si jedno ze čtyř témat a začněte kvíz hned teď.</div>
        <div className="tiles-header-content-wrapper topic-select">
            <div className="tiles-header-stack">
                {quizCategories.map((category) => (
                    <HeaderTile
                        key={category.id}
                        onClick={() => { onTopicSelect(category.id); }}
                        icon={category.icon}
                        title={category.title}
                        text={category.description}
                    />
                ))}
            </div>
        </div>
    </>
