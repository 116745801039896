import { useMemo } from "react";
import TopBar from "../../../../components/TopBar";
import { QuizCategoryType } from "../../questionsData";
import { QuestionDefinition, QuizState } from "../../types";
import { TopicSelectContent } from "./TopicSelectContent";
import { QuizInfoContent } from "./QuizInfoContent";
import { ResultContent } from "./ResultContent";

type TopBarSize = "small" | "medium" | "large" | "full";

type QuizTopBarProps = {
    question: QuestionDefinition;
    questionIndex: number;
    topic: QuizCategoryType | undefined;
    quizState: QuizState;
    onTopicSelect: (category: QuizCategoryType) => void;
    pulseViewGoBack: () => void;
    score: number;
    restartQuiz: () => void;
}

const quizStateToTopBarSize: Record<QuizState, TopBarSize> = {
    "selectScreen": "full",
    "quizScreen": "medium",
    "pusleScreen": "small",
    "resultScreen": "full",
};

export const QuizTopBar = ({ onTopicSelect, quizState, pulseViewGoBack, question, questionIndex, topic, score, restartQuiz }: QuizTopBarProps) => {
    const navItems = useMemo(() => quizState === "pusleScreen" ? [{
        label: "Zpět na kvíz",
        icon: "fa-regular fa-long-arrow-left",
        command: pulseViewGoBack,
    }] : [], [pulseViewGoBack, quizState]);

    return (
        <TopBar hideUserMenu hideHelpMenu size={quizStateToTopBarSize[quizState]} navigationItems={navItems}>
            {quizState === "selectScreen" && <TopicSelectContent onTopicSelect={onTopicSelect} />}
            {quizState === "quizScreen" && (
                <QuizInfoContent
                    question={question}
                    questionIndex={questionIndex}
                    topic={topic!}
                />
            )}
            {quizState === "resultScreen" && (
                <ResultContent restartQuiz={restartQuiz} score={score} />
            )}
        </TopBar>
    );
};