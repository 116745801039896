import React, { useCallback, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";

import { Pulse, useDeletePulseMutation, usePulsesQuery } from "../../api/generated";
import HeaderPanel from "../../components/HeaderPanel";
import Page from "../../components/Page";
import DataTable from "../../components/DataTable";
import { AppRoute, IAppRoute } from "../../AppRoute";
import DateFormat from "../../Common/Utils/DateFormat";
import DeleteDialog from "../../components/DeleteDialog";
import useToast from "../../hooks/useToast";
import { RequestError } from "../../api/graphqlBaseQueryTypes";
import PulseEditor from "./PulseEditor";
import { Link, generatePath, useNavigate } from "react-router-dom";

function Pulses() {
  const {
    isLoading: isLoadingPulses,
    isFetching: isFetchingPulses,
    refetch: refetchPulses,
    data: pulsesData,
    error,
  } = usePulsesQuery();
  const toast = useToast();
  const navigate = useNavigate();
  const [deletePulse] = useDeletePulseMutation();
  const [editedPulseId, setEditedPulseId] = useState<string | null>(null);

  function ContextMenu(pulse: Pulse) {
    const popupMenu = useRef<Menu>(null);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);

    const menuItems: MenuItem[] = [
      {
        label: "Detail",
        icon: "fa-regular fa-eye",
        command: () => {
          const path = generatePath(AppRoute.PulseDetail, {
            id: pulse.id,
          });
          navigate(path);
        },
      },
      {
        label: "Edit",
        icon: "fa-regular fa-pencil",
        command: () => {
          setEditedPulseId(pulse.id);
        },
      },
      {
        label: "Delete",
        icon: "fa-regular fa-trash",
        className: "danger",
        command: () => {
          setDeleteDialogVisible(true);
        },
      },
    ];

    const actionDeletePulse = async () => {
      try {
        await deletePulse({ id: pulse.id }).unwrap();
        refetchPulses();
        toast.show({
          severity: "success",
          detail: `The pulse '${pulse.name}' has been deleted successfully.`,
          life: 4000,
        });
      } catch (ex) {
        let e = ex as RequestError;
        let emsg = "An unknown error occurred while deleting the pulse";
        if (e.errors.length > 0) {
          emsg = e.errors[0].message;
        }
        toast.show({
          severity: "error",
          detail: emsg,
          life: 4000,
        });
      }
    };

    return (
      <div className="flex">
        <Menu model={menuItems} className="user-dropdown-menu" popup ref={popupMenu} />
        <Button className="p-button-link" icon="fa-regular fa-ellipsis-v" onClick={e => popupMenu.current?.toggle(e)} />
        <DeleteDialog
          message={
            <>
              Are you sure you want to delete pulse <span className="font-bold">{pulse.name}</span>?<br />
              This action can not be undone.
            </>
          }
          header="Delete Group"
          accept={actionDeletePulse}
          onHide={() => setDeleteDialogVisible(false)}
          visible={deleteDialogVisible}
        />
      </div>
    );
  }

  const onEditClose = (refresh: boolean) => {
    setEditedPulseId(null);
    if (refresh) {
      refetchPulses();
    }
  };

  const newPulse = useCallback(() => {
    window.location.href = AppRoute.NewPulse;
  }, []);

  function StoryLink(props: { msg: string | null | undefined }) {
    const parseName = (link: string) => {
      // parse name from [] brackets
      try {
        const name = link.split("[")[1].split("]")[0];
        return name;
      } catch (e) {
        return "";
      }
    };
    const parseUrl = (link: string) => {
      // parse url from () brackets
      try {
        const url = link.split("(")[1].split(")")[0];
        // url encode
        return url;
      } catch (e) {
        return "";
      }
    };
    if (!props.msg) {
      return <></>;
    }
    const storyId = props.msg.split("story_id:")[1].trim();
    const links = storyId.split(";");
    if (props.msg.startsWith("story_id:")) {
      const storyId = props.msg.split(":")[1].trim();
      return (
        <span>
          {links.map((link, index) => (
            <>
              <a href={`${parseUrl(link)}`} target="_blank" rel="noreferrer">
                <i className="fa fa-external-link" style={{ fontSize: "10px" }}></i> {parseName(link)}
              </a>{" "}
              &nbsp;{" "}
            </>
          ))}
        </span>
      );
    }
    return <></>;
  }

  function StatusCell(pulse: Pulse) {
    return (
      <div className="status-cell">
        {pulse.pulseData === null && (
          <span>
            <i className="fa fa-question-circle-o status-unknown"></i> Not executed
          </span>
        )}
        {pulse.pulseData !== null && pulse.pulseData?.runOk && (
          <span>
            <i className="fa fa-check-circle-o status-ok"></i> Executed (
            {DateFormat.toReadableString(pulse.pulseData?.created)}) &nbsp;
            <StoryLink msg={pulse.pulseData?.statusMessage} />
          </span>
        )}
        {pulse.pulseData !== null && !pulse.pulseData?.runOk && (
          <span>
            <i className="fa fa-times-circle-o status-error"></i> Failed (
            {DateFormat.toReadableString(pulse.pulseData?.created)}).{" "}
            <span className="status-message">{pulse.pulseData?.statusMessage}</span>
          </span>
        )}
      </div>
    );
  }

  function NameCell(pulse: Pulse) {
    return (
      <Link className="onclick" to={generatePath(AppRoute.PulseDetail, { id: pulse.id })}>
        {pulse.name}
      </Link>
    );
  }

  return (
    <Page windowTitle={pulsesRoute.title} className="page-pulses" errors={[error]}>
      <HeaderPanel titleText={pulsesRoute.title}>
        <Button label="New Pulse" className="p-button-sm" icon="fa-regular fa-plus" onClick={newPulse} />
      </HeaderPanel>
      <div className="content-wrapper">
        <DataTable
          lazy
          value={pulsesData?.pulses}
          loading={isLoadingPulses || isFetchingPulses}
          responsiveLayout="stack"
          emptyMessage="No pulses found"
        >
          <Column style={{ width: "40%" }} className="thin-row" header="Name" body={NameCell} />
          <Column style={{ width: "15%" }} field="topic" header="Topic" />
          <Column style={{ width: "30%" }} header="Status" body={StatusCell} />
          <Column style={{ width: "10%" }} header="Schedule" field="crontab" />
          <Column style={{ width: "5%" }} header="" body={ContextMenu}></Column>
        </DataTable>
      </div>
      <PulseEditor visible={editedPulseId != null} onClose={onEditClose} pulseId={editedPulseId} />
    </Page>
  );
}

const pulsesRoute: IAppRoute = {
  path: () => AppRoute.Pulse,
  page: <Pulses />,
  title: "Pulses",
  iconClassName: "fa fa-bar-chart",
  isAdminPage: false,
};

export default pulsesRoute;
