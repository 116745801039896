import { AppRoute, IAppRoute } from "../../AppRoute";
import Dashboard from "../Dashboard/Dashboard";

function Home() {

  return <Dashboard />;
}

const homeRoute: IAppRoute = {
  path: () => AppRoute.Home,
  page: <Home />,
  title: "Dashboard",
  iconClassName: "fa fa-th-large",
  isAdminPage: false,
};

export default homeRoute;
