import { useCallback } from "react";

import { AppRoute } from "../../AppRoute";
import useAppNavigate from "../../hooks/useAppNavigate";
import CounterTile from "./components/CounterTile";
import StatsTile from "./components/StatsTile";
import { useDashboardDataQuery } from "../../api/generated";

function FeaturedContent({ isExpertModeSet }: { isExpertModeSet: boolean }) {
  const navigate = useAppNavigate();
  const {data: dashboardData} = useDashboardDataQuery();

  const onPulseClick = useCallback(() => {
    navigate(AppRoute.Pulse);
  }, [navigate]);

  const featuredData = dashboardData?.dashboardData;

  return (
    <div className="tiles">
      <div className="tiles-stack">
        <CounterTile title="Active pulses" count={featuredData?.tiles?.pulses || 0} onClick={onPulseClick} />
        <CounterTile title="Failed pulses" count={featuredData?.tiles?.failedPulses || 0} onClick={onPulseClick} />
        <CounterTile
          title="Pulses scheduled for today"
          count={featuredData?.tiles?.pulsesScheduledToday || 0}
          onClick={onPulseClick}
        />
        <CounterTile title="Pulses executed today" count={featuredData?.tiles?.pulsesExecutedToday || 0} onClick={onPulseClick} />
      </div>
      <div className="tiles-placeholder-large">
        <StatsTile stats={featuredData?.statistics} />
      </div>
    </div>
  );
}

export default FeaturedContent;
